<template>
  <div class="flex flex-col mb-4">
    <label
      :for="label"
      class="text-m-form xl:text-base mb-2"
    >{{ label }}</label>
    <span class="relative w-full">
      <input
        v-model="inputValue"
        :name="label"
        :type="inputType"
        class="form-input w-full text-m-form xl:text-base p-3"
        :class="valid ? '' : 'border-red-600 border-2'"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="emitInputData"
        @blur="emitBlur"
      >
      <img
        v-show="props.password && inputType === 'text'"
        src="@/assets/images/eye-open-outline.svg"
        alt=""
        class="absolute right-1 top-2 cursor-pointer h-5 lg:h-6"
        @click="inputType = 'password'"
      >
      <img
        v-show="props.password && inputType === 'password'"
        src="@/assets/images/eye-slash.svg"
        alt=""
        class="absolute right-1 top-2 cursor-pointer h-5 lg:h-6"
        @click="inputType = 'text'"
      >
    </span>
    <div
      v-show="!valid"
      class="flex flex-row text-m-form xl:text-base pt-1 items-baseline"
    >
      <img
        src="@/assets/images/error.svg"
        alt=""
        class="pr-1"
      >
      <p class="xl:text-sm text-xs text-red-600">
        {{ errorMsg }}
      </p>
    </div>
    <div
      v-show="validMsg && valid && inputValue"
      class="flex flex-row text-m-form xl:text-base pt-1 items-baseline"
    >
      <img
        src="@/assets/images/check.svg"
        alt=""
        class="pr-1"
      >
      <p class="xl:text-sm text-xs text-smartmatch-green">
        {{ validMsg }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
const props = defineProps({
	label: String,
	inputKey: String,
	type: String,
	password: Boolean,
	disabled: Boolean,
	confirmPassword: Boolean,
	placeholder: String,
	valid: {
		type: Boolean,
		required: false,
		default: true,
	},
	errorMsg: {
		type: String,
		required: false,
	},
	validMsg: {
		type: String,
		required: false,
	},
});
const inputType = ref(props.type);
const inputValue = ref("");
const emit = defineEmits(["input", "blur"]);

function emitInputData() {
	emit("input", props.inputKey, inputValue.value);
}

function emitBlur() {
	emit("blur");
}
</script>
